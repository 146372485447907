import request from '@/utils/request'

/** 分页获取数据 */
export function getList(param) {
    return request({
        url: '/system/config/getList',
        method: 'GET',
        param: param
    })
}

/** 新增一条数据 */
export function saveConfig(data) {
    return request({
        url: '/system/config/put',
        method: 'POST',
        data: data
    })
}

/** 修改数据 */
export function editConfig(data) {
    return request({
        url: '/system/config/put',
        method: 'PUT',
        data: data
    })
}

/** 删除一条数据 */
export function remove(guid) {
    return request({
        url: '/system/config/del/'+guid,
        method: 'DELETE'
    })
}

/** 批量删除 */
export function deletes(ids) {
    return request({
        url: '/system/config/deletes',
        method: 'DELETE',
        data: ids
    })
}

/** 清空配置缓存 */
export function cacheClear() {
    return request({
        url: '/system/config/clear',
        method: 'DELETE'
    })
}

/** 获取配置 */
export function getConfig(guid) {
    return request({
        url: '/system/config/getConfig/'+guid,
        method: 'GET'
    })
}

/** 按分组获取配置 */
export function getConfigs(group) {
    return request({
        url: '/system/config/get/'+group,
        method: 'GET'
    })
}
