<template>
    <el-dialog :visible.sync="open" width="570px" :title="title" :close-on-click-modal="false">
        <el-row :gutter="11" >
            <el-form label-width="88px" ref="form" :size="$store.getters.size" :model="form" :rules="rules">
                <el-col :span="24">
                    <el-form-item label="配置名称" prop="configName">
                        <el-input type="text" placeholder="请输入配置名称" v-model="form.configName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="配置键名" prop="configKey">
                        <el-input type="text" placeholder="请输入配置键名" v-model="form.configKey"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="配置键值" prop="configValue">
                        <el-input type="text" placeholder="请输入配置键值" v-model="form.configValue"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="分组名称" prop="configGroup">
                        <el-input type="text" placeholder="请输入分组名称"  v-model="form.configGroup"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="是否启用" prop="status">
                        <el-switch v-model="form.status" active-text="启用" inactive-text="停用"></el-switch>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input type="textarea" v-model="form.remark" placeholder="备注" :autosize="{ minRows: 3, maxRows: 4}"></el-input>
                    </el-form-item>
                </el-col>
            </el-form>
        </el-row>
        <div slot="footer">
            <el-button type="primary" :size="$store.getters.size" @click="handleSubmit" :loading="loading">确 定</el-button>
            <el-button :size="$store.getters.size" @click="cancel">取 消</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import {getConfig, editConfig, saveConfig} from '@/api/system/config'

    export default {
        name: "ConfigEdit",
        data(){
            return {
                open: false,
                title: '',
                form: {},
                rules: {
                    configName: [{ required: true, message: "配置名称不能为空", trigger: "blur" }],
                    configKey: [{ required: true, message: "配置键名不能为空", trigger: "blur" }],
                    configValue: [{ required: true, message: "配置键值不能为空", trigger: "blur" }],
                    configGroup: [{ required: true, message: "配置分组不能为空", trigger: "blur" }]
                },
                loading: false
            }
        },
        methods: {
            /** 新增 */
            handleAdd(){
                this.open = true;
                this.title = "新增配置"
                this.reset();
            },
            /** 修改 */
            handleEdit(guid){
                this.reset();
                this.open = true;
                this.title = "修改配置"
                getConfig(guid).then(({code, data}) => {
                    if(code === 0){
                        this.form = data;
                    }
                })
            },
            /** 提交 */
            handleSubmit(){
                this.$refs['form'].validate(valid => {
                    if (valid && !this.loading){
                        this.loading = true;
                        if(this.form.guid){
                            editConfig(this.form).then(({code})=>{
                                this.loading = false;
                                if(code === 0){
                                    this.$message.success("修改成功");
                                    this.cancel();
                                    this.$parent.getTableData();
                                }
                            })
                        }else{
                            saveConfig(this.form).then(({code}) => {
                                this.loading = false;
                                if(code === 0){
                                    this.$message.success("新增成功");
                                    this.cancel();
                                    this.$parent.getTableData();
                                }
                            })
                        }
                    }
                })
            },
            /** 关闭 */
            cancel(){
                this.open = false;
                this.reset();
            },
            reset(){
                this.form = {
                    guid: undefined,
                    configName: undefined,
                    configKey: undefined,
                    configValue: undefined,
                    configGroup: undefined,
                    status: true,
                    remark: undefined
                }
            },
        }
    }
</script>

<style scoped>

</style>
