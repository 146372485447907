<template>
    <div class="app-container">
        <el-form :inline="true" ref="queryParams" :model="queryParams" :size="$store.getters.size">
            <el-form-item>
                <el-input placeholder="分组名" v-model="queryParams.group" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handlerQuery">查询</el-button>
            </el-form-item>
        </el-form>
        <el-row :gutter="10" class="mb20">
            <el-col :span="1.5">
                <el-button
                    type="primary"
                    plain
                    icon="el-icon-plus"
                    @click="handleAdd"
                    v-if="$app.haveRole('system:config:add')"
                    size="mini">新增</el-button>
                <el-button
                    type="danger"
                    plain
                    :disabled="multipleSelection.length<=0"
                    icon="el-icon-delete"
                    v-if="$app.haveRole('system:config:deletes')"
                    size="mini" @click="handleDeletes">批量删除</el-button>

                <el-button
                    type="warning"
                    plain
                    icon="el-icon-brush"
                    v-if="$app.haveRole('system:config:clear')"
                    size="mini" @click="handleClear">清除缓存</el-button>
            </el-col>
        </el-row>

        <el-table :data="tableData" :size="$store.getters.size" @selection-change="handleSelectionChange"
                row-key="guid" v-loading="loading" >
            <el-table-column type="selection" width="55" align="center"></el-table-column>
            <el-table-column label="配置名称" prop="configName"></el-table-column>
            <el-table-column label="配置键名" prop="configKey"></el-table-column>
            <el-table-column label="配置键值" prop="configValue"></el-table-column>
            <el-table-column label="分组名" prop="configGroup"></el-table-column>
            <el-table-column label="是否启用">
                <template slot-scope="scope">
                    {{scope.row.status ? '已启用':'未启用'}}
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="createTime"></el-table-column>
            <el-table-column label="备注" prop="remark"></el-table-column>
            <el-table-column label="操作" width="300">
                <template slot-scope="scope">
                    <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.row)">编辑</el-button>
                    <el-button type="text" icon="el-icon-delete" @click="handleRemove(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <page-item ref="pageItem" :total="total"></page-item>
        <config-edit ref="configEdit"/>
    </div>
</template>

<script>
    import PageItem from '@/components/PageItem'
    import ConfigEdit from './edit'
    import {getList, remove, deletes, cacheClear} from '@/api/system/config'

    export default {
        name: "SystemConfig",
        components: {PageItem, ConfigEdit},
        data(){
            return {
                queryParams: {
                    group: undefined
                },
                multipleSelection: [],
                tableData: [],
                loading: false,
                total: 0
            }
        },
        mounted() {
            this.getTableData();
        },
        methods: {
            /** 获取表数据 */
            getTableData(){
                if(this.loading){return;}
                this.queryParams.size = this.$refs['pageItem'].pageSize;
                this.queryParams.current = this.$refs['pageItem'].current;
                this.loading = true
                getList(this.queryParams).then(({code, data}) => {
                    this.loading = false
                    if(code === 0){
                        this.tableData = data.pageData;
                        this.total = data.total;
                    }
                })
            },
            /** 搜索 */
            handlerQuery(){
                this.$refs['pageItem'].current = 1;
                this.getTableData()
            },
            /** 添加 */
            handleAdd(){
                this.$refs['configEdit'].handleAdd();
            },
            /** 编辑 */
            handleEdit(row){
                this.$refs['configEdit'].handleEdit(row.guid);
            },
            /** 删除一条数据 */
            handleRemove(row){
                this.$confirm('你确定要删除该数据','提示', {
                    type: "warning"
                }).then(()=>{
                    remove(row.guid).then(({code})=>{
                        if(code === 0){
                            this.$message.success("删除成功");
                            this.getTableData();
                        }
                    })
                }).catch(()=>{})
            },
            /** 批量删除 */
            handleDeletes(){
                if(this.multipleSelection.length <= 0){
                    this.$message.error("未选择需要删除的数据")
                    return false;
                }
                this.$confirm('你确定要删除选中的数据','提示',{
                    type: "warning"
                }).then(() => {
                    const ids = this.multipleSelection.map(e => e.guid);
                    deletes({ids: ids}).then(({code}) => {
                        if(code === 0){
                            this.$message.success("批量删除成功")
                            this.getTableData();
                        }
                    })
                }).catch(()=>{})
            },
            /** 配置缓存清除 */
            handleClear(){
                this.$confirm('你确定要清除缓存?','提示',{
                    type: "warning"
                }).then(()=>{
                    cacheClear().then(({code})=>{
                        if(code === 0){
                            this.$message.success("操作成功");
                        }
                    })
                }).catch(()=>{})
            },
            /** 多选 */
            handleSelectionChange(val){
                this.multipleSelection = val;
            }
        }
    }
</script>

<style scoped>

</style>
